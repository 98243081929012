<template>
  <section class="reviews">
    <div class="wrapper">
      <h2 v-if="title" class="reviews__title">{{ title }}</h2>
      <div class="reviews__wrapper">
        <template v-for="(item, index) in reviews" :key="`video_review_${index}`">
          <div v-if="index === 0" class="reviews__item">
            <div class="reviews__content">
              <div class="reviews__content-left" @click="openMediaModal(0)">
                <div class="reviews__content-left-inner">
                  <video
                    v-if="item.previewVideo"
                    :controls="false"
                    playsinline
                    loop
                    muted
                    autoplay
                    :poster="`${url}${item.image.source}`"
                  >
                    <source :src="`${url}${item.previewVideo}`" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <UIPictureTag
                    v-else
                    :image-original="`${url}${item.image.source}`"
                    :image-seo="item.imageSeo"
                    :image-webp="`${url}${item.image.webp}`"
                  />
                </div>
              </div>

              <div class="reviews__content-right">
                <h3 v-if="item.title" class="reviews__content-title">{{ item.title }}</h3>
                <div v-if="item.descriptions" class="reviews__content-desc">
                  {{ item.descriptions }}
                </div>
                <div v-if="item.text" class="reviews__content-text">
                  {{ item.text }}
                </div>
                <UIButton
                  v-if="item.fullVideoLink.length > 0"
                  type="button"
                  size="l"
                  @click="openMediaModal(0)"
                  >Смотрите видео-отзыв</UIButton
                >
              </div>
            </div>
          </div>

          <CVideoCard
            v-else
            class="reviews__item"
            :title="item.title"
            :image="item.image"
            :image-seo="item.imageSeo"
            :preview-video-src="item.previewVideo"
            :description="item.descriptions"
            :full-video-link="item.fullVideoLink"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import CVideoCard from '~/components/blocks/videoCard/CVideoCard.vue';
import './styles.scss';
import UIButton from '~/components/UI/button/UIButton.vue';
import type { ImageItem, ImageSEO } from '~/types/pages';
import { computed, onUnmounted, useMediaModal, useRuntimeConfig } from '#imports';
import UIPictureTag from '~/components/UI/picture/UIPictureTag.vue';

interface Props {
  title: string;
  reviews: {
    image: ImageItem;
    imageSeo: ImageSEO;
    previewVideo: string;
    fullVideoLink: string;
    title: string;
    descriptions: string;
    text: string;
  }[];
}

const props = defineProps<Props>();
const url = useRuntimeConfig().public.siteUrl;

const dataSource = computed(() => ({
  html: `<iframe src="${props.reviews?.[0]?.fullVideoLink}?rel=0"
                    frameborder="0"
                    allow="
                     accelerometer;
                     autoplay;
                     clipboard-write;
                     encrypted-media;
                     gyroscope;
                     picture-in-picture;
                     web-share"
                    allowfullscreen>
                   </iframe>`,
}));

const { destroyMediaModal, openMediaModal } = useMediaModal([dataSource.value]);

onUnmounted(() => destroyMediaModal());
</script>
